$c-primary: #da1810;
$c-secandary: #541e4b;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #da1810;
$base-font-family: 'National2Web', sans-serif;
$font-family-heading: 'National2Web', sans-serif;

      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-bold.woff');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-regular.woff');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;

  .buttons {
    display: flex;
    justify-content: space-between;
    height: 100%;

    button {
      width: auto;
      height: 100%;
      min-width: 57%;
      white-space: break-spaces;
      line-height: 1;

      &:first-child {
        text-align: left;
        min-width: min-content !important;
      }

      + button {
        margin-left: .5rem;
      }
    }
  }

  .btnFull {
    width: 100%;
    height: 100%;

    button {
      width: 100%;
      height: 100%;
    }
  }
}
