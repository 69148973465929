$c-primary: #da1810;
$c-secandary: #541e4b;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #da1810;
$base-font-family: 'National2Web', sans-serif;
$font-family-heading: 'National2Web', sans-serif;

      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-bold.woff');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-regular.woff');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.progress {
  height: 1.25rem; //20
  background: #f5f5f5;
  position: relative;
  margin-top: 1rem; //16
  border-radius: 0.3125rem; //5

  .bar {
    border-radius: 0.3125rem; //5
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    transition: width 0.6s ease;
  }
}
