$c-primary: #da1810;
$c-secandary: #541e4b;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #da1810;
$base-font-family: 'National2Web', sans-serif;
$font-family-heading: 'National2Web', sans-serif;

      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-bold.woff');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-regular.woff');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.Select {
  position: relative;
  .hasError {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 3px;
    color: $c-danger;
  }
}

.leftPadding30Perc {
  padding: 0.5rem 0.7rem 0.5rem 40% !important;
}

:export {
  primary: var(--primary);
  secondary: var(--secondary);
  primary25: '#fff';
  primary50: lighten($c-secandary, 45%);
  gray: $c-grey;
  bodyGray: #fff;
}
