$c-primary: #da1810;
$c-secandary: #541e4b;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #da1810;
$base-font-family: 'National2Web', sans-serif;
$font-family-heading: 'National2Web', sans-serif;

      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-bold.woff');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-regular.woff');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);
  &.fill_view_port {
    height: 100%;
  }

  .captured_content {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    text-align: center;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    img {
      height: 4.75rem; //76
      width: auto;
      margin-bottom: 1.875rem; //30
    }
  }

  .capture_another {
    width: 100%;
    height: 2.8125rem; //45
    border-radius: 0.625rem; //10
    background: transparent;
    border: 1px solid var(--primary);
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    user-select: none;
    font-weight: 500;
    color: black;
    z-index: 2;
  }

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-bottom: 1.5rem; //24
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
  }

  .description {
    text-align: left;
  }

  .option_list {
    margin-top: 1.5rem; //24
    margin-bottom: 1.5rem; //24

    .option_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      height: 2.8125rem; //45
      margin-bottom: 0.6875rem; //11
      cursor: pointer;
      &.checked {
        background: #f4f4f4;
        border-radius: 10px;
      }

      .check_mark_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 3.3125rem; //53
        height: 2.8125rem; //45

        img {
          width: 1.375rem; //22
          height: auto;
        }
      }

      .option_title {
        flex: 1;
      }
    }
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }
}
