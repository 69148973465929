$c-primary: #da1810;
$c-secandary: #541e4b;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #da1810;
$base-font-family: 'National2Web', sans-serif;
$font-family-heading: 'National2Web', sans-serif;

      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-bold.woff');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-regular.woff');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-bottom: 1.5rem; //24
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
  }

  .description {
    text-align: left;
  }
}
