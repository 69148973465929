$c-primary: #da1810;
$c-secandary: #541e4b;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #da1810;
$base-font-family: 'National2Web', sans-serif;
$font-family-heading: 'National2Web', sans-serif;

      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-bold.woff');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-regular.woff');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .container {
    width: 400px;
    margin: 120px auto 0;

    .logo {
      margin: 0 auto 35px;
      display: block;
    }

    .block {
      background: #fff;
      box-shadow: 0 2px 20px #eee;

      .title {
        padding: 20px;
        text-align: center;
        background: var(--primary);
        color: #fff;
      }

      .content {
        padding: 20px 35px;

        p {
          opacity: 0.8;
          font-size: 14px;
          text-align: center;
          margin: 0 auto 30px;
          max-width: 300px;

          a {
            color: inherit;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
