$c-primary: #da1810;
$c-secandary: #541e4b;
$dots: false;
$loading-bg-overrided: white;
$progress-bar-overided: #da1810;
$base-font-family: 'National2Web', sans-serif;
$font-family-heading: 'National2Web', sans-serif;

      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-bold.woff');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'National2Web';
        src: url('/fonts/national-2-web-regular.woff');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  height: 100%;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.01875rem; //-0.332143px;

  .content {
    margin-top: 1.5rem; //24
  }

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    line-height: 1.1;
    letter-spacing: -0.03125rem; //-0.5px
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }

  .reduce-size-25perc {
    width: 75%;
    margin: 0 auto;
  }
}
